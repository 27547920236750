export const BASE_FONT_SIZE = 16

// Function to convert font-size to pixels
// Units handled: px, em, rem, %, ex, ch, lh, vw, vh, vmin, vmax
// Units NOT handled: print media sizes (pt, pc, etc.), keywords (small, large, etc.)
function convertToNumericPixels(size: string): number {
  if (size.endsWith('px')) {
    return parseFloat(size)
  } else if (size.endsWith('em') || size.endsWith('rem')) {
    return parseFloat(size) * BASE_FONT_SIZE
  } else if (size.endsWith('ex')) {
    // 'ex' is relative to the x-height of the current font - assuming 'ex' is half of the font size
    return parseFloat(size) * (BASE_FONT_SIZE / 2)
  } else if (size.endsWith('ch')) {
    // 'ch' is relative to the width of the "0" (zero) character - assuming 'ch' is the width of the "0" character, about half of the font size
    return parseFloat(size) * (BASE_FONT_SIZE / 2)
  } else if (size.endsWith('%')) {
    return (parseFloat(size) / 100) * BASE_FONT_SIZE
  } else if (size.endsWith('lh')) {
    // 'lh' is relative to the line height of the elements - assuming line-height is the same as BASE_FONT_SIZE for simplicity
    return parseFloat(size) * BASE_FONT_SIZE
  } else if (size.endsWith('vw')) {
    // 'vw' is 1% of the viewport's width
    return (parseFloat(size) / 100) * window.innerWidth
  } else if (size.endsWith('vh')) {
    // 'vh' is 1% of the viewport's height
    return (parseFloat(size) / 100) * window.innerHeight
  } else if (size.endsWith('vmin')) {
    // 'vmin' is 1% of the viewport's smaller dimension
    return (parseFloat(size) / 100) * Math.min(window.innerWidth, window.innerHeight)
  } else if (size.endsWith('vmax')) {
    // 'vmax' is 1% of the viewport's larger dimension.
    return (parseFloat(size) / 100) * Math.max(window.innerWidth, window.innerHeight)
  } else {
    return BASE_FONT_SIZE
  }
}

export default function computeFontSizeInPixels() {
  // get the html font-size
  const htmlElement = document.documentElement
  const computedStyle = window.getComputedStyle(htmlElement)
  const htmlFontSize = computedStyle.fontSize

  // convert the font-size to pixels
  const currentFontSizeInNumericPixels = convertToNumericPixels(htmlFontSize)

  // ensure at least "16 for a mininum
  const computedFontSize = Math.max(BASE_FONT_SIZE, currentFontSizeInNumericPixels)

  return `${computedFontSize}px`
}
